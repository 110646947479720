/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Rails functionality
// window.Rails = require("@rails/ujs")

import "src/carsdiagram"

// ADD YOUR JAVACSRIPT HERE
// $(window).scroll(function() {
//   // If user didn't scroll 1200px set default z-index
//   if ($(this).scrollTop()<900) {
//       $(".defaultpage-hero" ).css( "z-index", 2);
//       $(".defaultpage-hero" ).css( "display", "flex");
//   } else {
//       // If user scrolled 1200px change logo's z-index to 9999
//       $(".defaultpage-hero" ).css( "z-index", -1);
//       $(".defaultpage-hero" ).css( "display", "none");
//   }
// });

// // ADD YOUR JAVACSRIPT HERE
// $(window).scroll(function() {
//   // If user didn't scroll 1200px set default z-index
//   if ($(this).scrollTop()<900) {
//       $(".defaultpage-hero" ).css( "z-index", 2);
//       $(".defaultpage-hero" ).css( "display", "flex");
//   } else {
//       // If user scrolled 1200px change logo's z-index to 9999
//       $(".defaultpage-hero" ).css( "z-index", -1);
//       $(".defaultpage-hero" ).css( "display", "none");
//   }
// });
// window.initMap = function(...args) {
//   const event = document.createEvent("Events")
//   event.initEvent("google-maps-callback", true, true)
//   event.args = args
//   window.dispatchEvent(event)
// }
